const aws_exports = {
  aws_project_region: 'us-west-2',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_oxXT08F6L',
  aws_user_pools_web_client_id: '6mcciedjcm8esf38h1s573e5t6',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint: 'https://vwtvdkjcfvhz3nhtjzjwssqfxy.appsync-api.us-west-2.amazonaws.com/graphql',
}
export default aws_exports;

